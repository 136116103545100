import jwt from 'jsonwebtoken'

const SEED_NUMBER = 7846

// used to hide groupId values for group Auto assign links (user registration links)
export const groupAutoAssignObscureGroupId = num =>
  (num * SEED_NUMBER).toString(16)
export const groupAutoAssignUnobscureGroupId = num =>
  parseInt(num, 16) / SEED_NUMBER

export const jwtEncode = orgId =>
  jwt.sign({ orgId }, process.env.GATSBY_JWT_SECRET)
