import React, { useContext, useEffect, useState } from 'react'
import T from 'prop-types'
import slugify from 'slugify'
import { Link as RouterLink } from 'gatsby'
import { useMutation, useQuery, useManualQuery } from 'graphql-hooks'
import { Formik, Form, Field } from 'formik'
import {
  withStyles,
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  TableRow,
  TableCell,
  Typography,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import { TextField as TextFieldFormik } from 'formik-material-ui'
import {
  DeleteForever,
  KeyboardArrowUp,
  Assignment as AssignmentIcon,
} from '@material-ui/icons'
import * as Yup from 'yup'
import { get, sortBy } from 'lodash'

import {
  createGroupMutation,
  getGroups,
  getOrganizations,
  deleteGroupMutation,
  updateAssessmentGroupIdForDeleteMutation,
  updateUserGroupIdForDeleteMutation,
  getGroupCountOfParentIds,
} from '../../../queries'

import { ConfirmDialog, ORG_TYPES } from 'gatsby-components'

import useAdminTable from '../../hooks/useAdminTable'

import { AuthContext } from '../authorization/AuthWrapper'
import { jwtEncode } from '../authentication/utils'
import SelectPicker from './SelectPicker'

const GroupSchema = Yup.object().shape({
  name: Yup.string().required(),
  organizationId: Yup.number().required(),
})

const headers = [
  { id: 'id', label: 'ID', sortable: true },
  { id: 'name', label: 'Group', sortable: true },
  { id: 'organization', label: 'Organisation' },
  {
    id: 'registrationLink',
    label: 'User Registration Link',
    cellProps: {
      align: 'center',
    },
  },
  {
    id: 'delete',
    label: 'Delete',
    cellProps: {
      align: 'right',
    },
  },
]

function UserGroups({ whereClause, classes }) {
  const { getUserTokenData, getUserAuth } = useContext(AuthContext)
  const userTokenData = getUserTokenData()
  // =======================================================================================
  // TODO: This component currently uses two forms of the create group mutation; one
  // that requires a parent group ID, and one that doesn't. This is because at the time of
  // merging the auth code from assess-base (AB) and knowledge-base (KB) into the components
  // package, AB had a well-defined concept of parent group whilst KB didn't. Once a proper
  // parent group concept has been worked out for KB, the no-parent version of the mutation
  // can be removed from here and from components/queries/groups and just one createGroup
  // mutation be used in all cases. Use of the no-parent group mutation must be enabled via
  // the AuthWrapper.
  // =======================================================================================
  const [createGroup] = useMutation(createGroupMutation)
  const [deleteGroup] = useMutation(deleteGroupMutation)
  const [updateAssessmentGroupId] = useMutation(
    updateAssessmentGroupIdForDeleteMutation
  )
  const [updateUserGroupId] = useMutation(updateUserGroupIdForDeleteMutation)
  const [getParentIdCount] = useManualQuery(getGroupCountOfParentIds)

  const { data } = useQuery(getGroups, {
    variables: {
      orderBy: { id: 'asc' },
    },
  })

  const orgData = get(
    useQuery(getOrganizations, {
      variables: {
        orderBy: { name: 'asc' },
      },
    }),
    'data.organization',
    []
  )
  const orgPickerData = orgData.map(({ id, name }) => ({
    key: id,
    value: name,
  }))

  const allGroups = get(data, 'group2', [])

  const [
    groupDeleteNewGroupIdAssessments,
    setGroupDeleteNewGroupIdAssessments,
  ] = useState(false)
  const [groupDeleteNewGroupIdUsers, setGroupDeleteNewGroupIdUsers] = useState(
    false
  )
  const [groupDeleteErrorMessage, setGroupDeleteErrorMessage] = useState(null)

  const [registrationLinkStart, setRegistrationLinkStart] = useState(
    'https://assessbase.digitalefqm.com'
  )
  // to set beginning of registrationLink since since window contains SSR
  // set default state to prod to avoid the jumping look while loading page
  useEffect(() => {
    const [path] = window.location.href.split`/admin/groups`
    setRegistrationLinkStart(path)
  }, [])

  const canCreateGroups = getUserAuth('group-admin')
  const groupMustHaveParent =
    getUserAuth('group-admin') && !getUserAuth('org-admin')

  const { refetch: refetchGroups, table } = useAdminTable({
    query: getGroups,
    headers,
    variables: {
      whereClause,
    },
    renderTableBody: (data, { refetch: refetchGroups }) => {
      const doDeleteGroup = async id => {
        const { data } = await getParentIdCount({
          variables: {
            parentId: id,
          },
        })

        const hasChildGroups = get(data, 'group2_aggregate.aggregate.count') > 0
        if (hasChildGroups) {
          setGroupDeleteErrorMessage(
            'The group you tried to delete has child groups, delete them first before proceeding'
          )
          throw Error()
        }

        await updateAssessmentGroupId({
          variables: {
            oldGroupId: id,
            newGroupId: groupDeleteNewGroupIdAssessments,
          },
        })

        await updateUserGroupId({
          variables: {
            oldGroupId: id,
            newGroupId:
              groupDeleteNewGroupIdUsers === false
                ? null
                : groupDeleteNewGroupIdUsers,
          },
        })

        await deleteGroup({ variables: { id } })
        refetchGroups()
      }

      return data.group2.map(group => {
        const groupDeletePickerData = sortBy(
          allGroups
            .filter(
              ({ id, organization }) =>
                group.organization.id === organization.id && group.id !== id
            )
            .map(({ id, name }) => ({
              name,
              value: id,
            })),
          g => g.name.toLowerCase()
        )

        return (
          <TableRow key={group.id} data-testid="user-groups" size="small">
            <TableCell>
              <Typography variant="body2">{group.id}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                <RouterLink to={`${group.id}/${slugify(group.name)}`}>
                  {group.name}
                </RouterLink>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                <RouterLink
                  to={`${group.organization.id}/${slugify(
                    group.organization.name
                  )}`}
                >
                  {group.organization.name}
                </RouterLink>
              </Typography>
            </TableCell>
            <TableCell>
              <Input
                fullWidth
                id={`group${group.id}`}
                value={`${
                  process.env.GATSBY_SVELTEKIT_APP_HOST
                }/sign-up?t=${jwtEncode(group.id)}`}
                className={classes.linkField}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={classes.copyLinkButton}
                      onClick={() => {
                        const copyText = document.querySelector(
                          `#group${group.id}`
                        )
                        copyText.select()
                        document.execCommand('copy')
                      }}
                    >
                      <AssignmentIcon className={classes.copyIcon} />
                      Copy Link
                    </Button>
                  </InputAdornment>
                }
              />
            </TableCell>
            <TableCell align="right" padding="none">
              {group.id != userTokenData.groupId && (
                <ConfirmDialog
                  title={`Delete group “${group.name}”?`}
                  onConfirm={() => doDeleteGroup(group.id)}
                  okayLabel="Delete"
                  submitDisabled={groupDeleteNewGroupIdAssessments === false}
                  waitForAsync
                  inputElement={
                    <DialogContent>
                      <DialogContentText>
                        Where would you like this groups old assessments to be
                        reassigned?
                      </DialogContentText>
                      <SelectPicker
                        data={groupDeletePickerData}
                        selectedValue={groupDeleteNewGroupIdAssessments}
                        handleChange={val => (
                          setGroupDeleteNewGroupIdAssessments(val),
                          setGroupDeleteErrorMessage(null)
                        )}
                      />
                      {!groupDeletePickerData.length && (
                        <DialogContentText color="error">
                          This list is empty because the organisation does not
                          contain any other groups, please make a new group in
                          this organisation before deleting this group.
                        </DialogContentText>
                      )}
                      <DialogContentText
                        className={classes.userGroupSelectTitle}
                      >
                        Users can be reassigned or they can be left unassigned
                      </DialogContentText>
                      <SelectPicker
                        data={[
                          { value: false, name: 'Leave users unassigned' },
                          ...groupDeletePickerData,
                        ]}
                        selectedValue={groupDeleteNewGroupIdUsers}
                        handleChange={val => (
                          setGroupDeleteNewGroupIdUsers(val),
                          setGroupDeleteErrorMessage(null)
                        )}
                      />
                      {!!groupDeleteErrorMessage && (
                        <DialogContentText color="error">
                          {groupDeleteErrorMessage}
                        </DialogContentText>
                      )}
                    </DialogContent>
                  }
                  onOpen={() => {
                    if (!groupDeletePickerData.length) {
                      setGroupDeleteErrorMessage(
                        'Before deleting this group, your assessments must have a new group to which they can be assigned. Please create a new group and then try again.'
                      )
                    }
                  }}
                  onCancel={() => (
                    setGroupDeleteNewGroupIdAssessments(false),
                    setGroupDeleteNewGroupIdUsers(false),
                    setGroupDeleteErrorMessage(null)
                  )}
                >
                  <IconButton className={classes.actionButton}>
                    <DeleteForever />
                  </IconButton>
                </ConfirmDialog>
              )}
            </TableCell>
          </TableRow>
        )
      })
    },
  })

  const formInitialValues = {
    name: '',
    organizationId: Number(userTokenData.orgId),
    parentGroup: groupMustHaveParent ? userTokenData.groupId : false,
  }

  return (
    <>
      {canCreateGroups && (
        <Formik
          validationSchema={GroupSchema}
          initialValues={formInitialValues}
          onSubmit={async (
            { name, organizationId, parentGroup },
            { setSubmitting, resetForm }
          ) => {
            try {
              // TODO: See comment above on createGroupMutation.
              await createGroup({
                variables: {
                  name,
                  organizationId,
                  parentId: parentGroup === false ? null : parentGroup,
                },
              })
              refetchGroups()
              resetForm(formInitialValues)
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            // useEffect(() => {
            //   setFieldValue('parentGroup', false)
            // }, [values.organizationId])

            const parentGroupPickerData = [
              ...(groupMustHaveParent
                ? []
                : [{ key: false, value: 'No Parent Group' }]),
              ...get(
                orgData.find(({ id }) => values.organizationId === id),
                'groups',
                []
              ).map(({ id, name }) => ({ key: id, value: name })),
            ]

            return (
              <Form>
                <Grid
                  container
                  alignItems="flex-end"
                  spacing={2}
                  className={classes.newGroup}
                >
                  <Grid item xs={3}>
                    <InputLabel htmlFor="group-name-input">
                      <Typography
                        variant="h4"
                        gutterBottom
                        className={classes.inputLabel}
                      >
                        Enter new group name
                      </Typography>
                    </InputLabel>
                    <Field
                      id="group-name-input"
                      component={TextFieldFormik}
                      name="name"
                      placeholder="name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel htmlFor="group-organization-input">
                      <Typography
                        variant="h4"
                        gutterBottom
                        className={classes.inputLabel}
                      >
                        Select organisation
                      </Typography>
                    </InputLabel>
                    <Field
                      id="group-organization-input"
                      component={TextFieldFormik}
                      select
                      name="organizationId"
                      fullWidth
                      SelectProps={{
                        classes: {
                          root: classes.selectRoot,
                          select: classes.selectElem,
                          icon: classes.selectIcon,
                        },
                        IconComponent: KeyboardArrowUp,
                      }}
                    >
                      {orgPickerData.map(org => (
                        <MenuItem key={org.key} value={org.key}>
                          {org.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel htmlFor="parent-group-input">
                      <Typography
                        variant="h4"
                        gutterBottom
                        className={classes.inputLabel}
                      >
                        Select parent group (optional)
                      </Typography>
                    </InputLabel>
                    <Field
                      id="parent-group-input"
                      component={TextFieldFormik}
                      select
                      name="parentGroup"
                      fullWidth
                      SelectProps={{
                        classes: {
                          root: classes.selectRoot,
                          select: classes.selectElem,
                          icon: classes.selectIcon,
                        },
                        IconComponent: KeyboardArrowUp,
                      }}
                    >
                      {parentGroupPickerData.map(group => (
                        <MenuItem key={group.key} value={group.key}>
                          {group.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      Create group
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      )}
      {table}
    </>
  )
}
UserGroups.propTypes = {
  whereClause: T.object.isRequired,
  classes: T.object.isRequired,
}

const styles = theme => ({
  newGroup: {
    marginBottom: theme.spacing(4),
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
  copyLinkButton: {
    padding: theme.spacing(0.5, 1),
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  copyIcon: {
    marginRight: theme.spacing(1),
  },
  // TODO: Move these to a generic field component shared with modal, criterion part, etc
  inputLabel: {
    color: theme.palette.primary.dark,
    fontWeight: 700,
    marginTop: theme.spacing(1),
  },
  selectRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  selectElem: {
    flexGrow: 1,
  },
  selectIcon: {
    color: theme.palette.secondary.main,
  },
  userGroupSelectTitle: {
    marginTop: theme.spacing(5),
  },
})

export default withStyles(styles)(UserGroups)
