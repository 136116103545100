import React, { useState } from 'react'
import {
  withStyles,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useMutation } from 'graphql-hooks'

import { getAssessmentTypes } from 'efqm-theme/assessments/getAssessmentParts'
import {
  AssessmentStatusChip,
  ConfirmDialog,
  UserFilter,
} from 'gatsby-components'
import {
  getNonUserQuestionnairesData,
  deleteNonUserQuestionnaireMutation,
} from '../../queries'
import { formatDate } from 'gatsby-components/src/utils/date'
import QueryTable from '../QueryTable'
import { FeedbackReportLink } from '../report-links'
import DownloadCSVButton from './DownloadCSVButton'

function FreeQuestionnaireTable({ classes, isAdmin = true }) {
  const { t, i18n } = useTranslation()
  const assessmentTypesContext = getAssessmentTypes(i18n)

  const questionnairesArr = assessmentTypesContext
    .filter(def => def.type === 'lens')
    .reduce(
      (a, def) => (a.push(...def.assessmentTypes), a),
      JSON.parse(JSON.stringify(assessmentTypesContext))
    )
    .filter(
      def =>
        def.type === 'assessment' &&
        [
          'questionnaire-2020',
          'rapid-organisation-diagnostic',
          'rapid-organisation-diagnostic-2025',
        ].includes(def.lensVersionOf || def.key)
    )

  const questionnairesObj = questionnairesArr.reduce(
    (a, { key, name, showFeedbackReport }) => ({
      ...a,
      [key]: { key, name, showFeedbackReport },
    }),
    {}
  )

  const [filterAssessments, setFilterAssessments] = useState([])
  const [filterEmail, setFilterEmail] = useState('')
  const [filterType, setFilterType] = useState('all')
  const [queryTableVariables, setQueryTableVariables] = useState({})
  const [resetQueryOffset, setResetQueryOffset] = useState(null)

  const headers = [
    {
      id: 'title',
      label: t('Questionnaire Name'),
      sortable: true,
      includeInCSV: true,
    },
    {
      id: 'created_at',
      label: t('Created At'),
      sortable: true,
      includeInCSV: true,
      isDate: true,
    },
    {
      id: 'key',
      label: t('Questionnaire Type'),
      sortable: true,
      includeInCSV: true,
    },
    { id: 'email', label: t('Email'), includeInCSV: true },
    { id: 'status', label: t('Status'), sortable: true, includeInCSV: true },
    {
      id: 'country',
      label: t('quest.csv.countryHeader', 'Country'),
      includeInCSV: true,
      includeInPage: false,
    },
    {
      id: 'industry_sector',
      label: t('quest.csv.sectorHeader', 'Industry Sector'),
      includeInCSV: true,
      includeInPage: false,
    },
    {
      id: 'organisation_size',
      label: t('quest.csv.orgSizeHeader', 'Organisation Size'),
      includeInCSV: true,
      includeInPage: false,
    },
    { id: 'feedback', label: t('Feedback') },
    { id: 'delete', label: t('Delete') },
  ]

  const [deleteNonUserQuestionnaire] = useMutation(
    deleteNonUserQuestionnaireMutation
  )

  const onDelete = (key, email, refetch) => async () => {
    if (
      (
        await deleteNonUserQuestionnaire({
          variables: {
            key,
            email,
          },
        })
      ).error === false
    ) {
      setFilterAssessments([...filterAssessments, { email, key }])
    }

    refetch()
  }

  return (
    <>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.tableTools}
      >
        <Grid
          container
          xs={8}
          spacing={4}
          item
          justify="flex-start"
          alignItems="flex-end"
        >
          <Grid item>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.inputLabel}
            >
              Filter by Email
            </Typography>
            <UserFilter setFilterText={setFilterEmail} />
          </Grid>
          <Grid item>
            <InputLabel htmlFor="group-type-input">
              <Typography
                variant="h4"
                gutterBottom
                className={classes.inputLabel}
              >
                Select questionnaire type
              </Typography>
            </InputLabel>
            <TextField
              id="questionnaire-key-input"
              select
              name="type"
              fullWidth
              SelectProps={{
                classes: {
                  root: classes.selectRoot,
                  select: classes.selectElem,
                  icon: classes.selectIcon,
                },
                IconComponent: KeyboardArrowUpIcon,
              }}
              defaultValue={'all'}
              value={filterType}
              onChange={e => (
                resetQueryOffset(), setFilterType(e.target.value)
              )}
            >
              {[{ name: 'All', key: 'all' }, ...questionnairesArr].map(
                questionnaire => (
                  <MenuItem key={questionnaire.key} value={questionnaire.key}>
                    {questionnaire.name}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>
        </Grid>
        <Grid item>
          {filterType !== 'all' && (
            <DownloadCSVButton
              variables={queryTableVariables}
              type={questionnairesArr.find(({ key }) => key === filterType)}
              columns={headers
                .filter(header => header.includeInCSV)
                .map(({ id, label, isDate }) => ({ key: id, label, isDate }))}
            />
          )}
        </Grid>
      </Grid>
      <QueryTable
        headers={headers}
        query={getNonUserQuestionnairesData}
        orderBy={{ created_at: 'desc' }}
        variables={{
          emailFilter: `%${filterEmail}%`,
          questionnaireTypeFilter: `%${
            filterType === 'all' ? '' : filterType
          }%`,
        }}
        renderTableBody={({
          data,
          variables: queryVariables,
          resetOffset,
          refetch,
        }) => {
          if (
            JSON.stringify(queryVariables) !==
            JSON.stringify(queryTableVariables)
          ) {
            // Can't use a hook within render table body
            // used to get query variables out of render and into Download CSV button
            setQueryTableVariables(queryVariables)
          }
          if (resetQueryOffset === null) {
            setResetQueryOffset(() => resetOffset)
          }

          return data.questionnaire_non_users
            .filter(
              ({ email, key }) =>
                !filterAssessments.filter(
                  filtered => filtered.email === email && filtered.key === key
                ).length
            )
            .map((assessment, index) => {
              const { key, title, email, created_at, status } = assessment

              return (
                <TableRow
                  hover
                  key={index}
                  size="small"
                  className={classes.row}
                >
                  <TableCell>
                    <Typography variant="body2">{title}</Typography>
                  </TableCell>
                  <TableCell>{formatDate(created_at)}</TableCell>
                  <TableCell>{questionnairesObj[key].name}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>
                    <AssessmentStatusChip status={status} />
                  </TableCell>
                  <TableCell>
                    <FeedbackReportLink
                      assessment={assessment}
                      params={{ email, key }}
                      visible={
                        status === 'submitted' ||
                        status === 'closed' ||
                        questionnairesObj[key].showFeedbackReport
                      }
                      spacing={1}
                      text={t('View')}
                    />
                  </TableCell>
                  {isAdmin && (
                    <TableCell padding="none" align="center">
                      <ConfirmDialog
                        onConfirm={onDelete(key, email, refetch)}
                        title={t('Delete questionnaire', {
                          assessmentTitle: title,
                        })}
                        text={t(
                          'This questionnaire will be deleted. This cannot be undone.'
                        )}
                      >
                        <IconButton className={classes.deleteIcon}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </ConfirmDialog>
                    </TableCell>
                  )}
                </TableRow>
              )
            })
        }}
      />
    </>
  )
}

const styles = theme => ({
  tableTools: {
    marginBottom: '10px',
  },
  row: {
    '& .titleChip': {
      backgroundColor: theme.palette.background.paper,
      height: 'auto',
      marginLeft: '-8px',
      '& .MuiSvgIcon-root': {
        color: theme.palette.background.paper,
        '& .rowHover': {
          color: 'red',
        },
      },
      '& .MuiChip-label': {
        display: 'flex',
        alignItems: 'center',
        '& > * + * ': {
          marginLeft: theme.spacing(1),
        },
      },
    },
  },
  deleteIcon: {
    color: theme.palette.tertiary ? theme.palette.tertiary.main : null,
    marginLeft: '-12px',
  },
  selectRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  selectElem: {
    flexGrow: 1,
  },
  selectIcon: {
    color: theme.palette.secondary.main,
  },
  inputLabel: {
    color: theme.palette.primary.dark,
    fontWeight: 700,
    marginTop: theme.spacing(1),
  },
})

export default withStyles(styles)(FreeQuestionnaireTable)
