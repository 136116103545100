const keyInfo = [
  'challenges-and-strategy',
  'management-structure',
  'market-offerings-and-customers',
  'operations-partners-suppliers',
  'facts-and-figures',
]

// Each assessment type specifies a filename and a filter:
// - The filename is the name of the JSON file containing the assessment
//   configuration;
// - The filter is applied to the assessment configuration after it is
//   loaded to produce the required assessment type.
const assessmentTypes = {
  questionnaire: {
    filename: 'questionnaire.json',
    filter: assessment => assessment,
  },
  'rapid-organisation-diagnostic': {
    filename: 'questionnaire-rapid-organisation-diagnostic.json',
    filter: assessment => assessment,
  },
  'rapid-organisation-diagnostic-2025': {
    filename: 'questionnaire-rapid-organisation-diagnostic-2025.json',
    filter: assessment => assessment,
  },
  'questionnaire-2020': {
    filename: 'questionnaire-2020.json',
    filter: assessment => assessment,
  },
  'efqm-2020': {
    filename: 'business-matrix.json',
    filter: (matrix, key) => {
      const result = deepClone(matrix)
      result.key = key
      result.matrixType = 'basic'
      result.orderIndex = 2
      result.logoAsset = 'assess-2-img'
      result.name = result.typeNames[key]
      result.shortDescription = result.shortDescriptions[key]
      // Select first subcriteria of each pillar.
      result.pillars.forEach(pillar => {
        pillar.criteria.forEach(criteria => {
          criteria.parts = criteria.parts.slice(0, 1)
        })
      })
      // Select first scoring item at top level and for each pillar; clear the
      // display name for each score item (scoring group name only is used).
      result.scoring.forEach(item => {
        item.scores = item.scores.slice(0, 1)
        item.scores[0].name = ''
      })
      result.pillars.forEach(pillar => {
        if (pillar.scoring) {
          pillar.scoring.forEach(item => {
            item.scores = item.scores.slice(0, 1)
            item.scores[0].name = ''
          })
        }
      })
      return result
    },
  },
  'efqm-2020-advanced': {
    filename: 'business-matrix.json',
    filter: (matrix, key) => {
      const result = deepClone(matrix)
      result.key = key
      result.matrixType = 'advanced'
      result.orderIndex = 3
      result.logoAsset = 'assess-3-img'
      result.name = result.typeNames[key]
      result.lensVersionName = result.lensVersionNameTypeNames[key]
      result.shortDescription = result.shortDescriptions[key]
      // Number subcriteria.
      let cidx = 1
      result.pillars.forEach(pillar => {
        pillar.criteria.forEach(criteria => {
          criteria.parts.forEach((part, pidx) => {
            const [table] = part.tables
            // Produce a name like "1.1: Xxxx"
            table.name = `${cidx}.${pidx + 1}: ${table.name}`
          })
          cidx++
        })
      })
      return result
    },
  },
  'organisation-diagnostic-2025': {
    filename: 'business-matrix.json',
    filter: (matrix, key) => {
      const result = deepClone(matrix)
      result.key = key
      result.matrixType = 'advanced'
      result.orderIndex = 3
      result.logoAsset = 'assess-3-img'
      result.name = result.typeNames[key]
      result.lensVersionName = result.lensVersionNameTypeNames[key]
      result.shortDescription = result.shortDescriptions[key]
      // Number subcriteria.
      let cidx = 1
      result.pillars.forEach(pillar => {
        pillar.criteria.forEach(criteria => {
          criteria.parts.forEach((part, pidx) => {
            const [table] = part.tables
            // Produce a name like "1.1: Xxxx"
            table.name = `${cidx}.${pidx + 1}: ${table.name}`
          })
          cidx++
        })
      })
      return result
    },
  },
  'advanced-organisation-diagnostic-2025': {
    filename: 'business-matrix.json',
    filter: (matrix, key) => {
      const result = deepClone(matrix)
      result.key = key
      result.matrixType = 'advanced'
      result.orderIndex = 3
      result.logoAsset = 'assess-3-img'
      result.name = result.typeNames[key]
      result.lensVersionName = result.lensVersionNameTypeNames[key]
      result.shortDescription = result.shortDescriptions[key]
      // Number subcriteria.
      let cidx = 1
      result.pillars.forEach(pillar => {
        pillar.criteria.forEach(criteria => {
          criteria.parts.forEach((part, pidx) => {
            const [table] = part.tables
            // Produce a name like "1.1: Xxxx"
            table.name = `${cidx}.${pidx + 1}: ${table.name}`
          })
          cidx++
        })
      })
      return result
    },
  },
  innovation: {
    filename: 'innovation-lens.json',
    filter: assessment => assessment,
  },
  environment: {
    filename: 'environment-lens.json',
    filter: assessment => assessment,
  },
  disruption: {
    filename: 'disruption-lens.json',
    filter: assessment => assessment,
  },
  circularEconomy: {
    filename: 'circular-economy-lens.json',
    filter: assessment => assessment,
  },
  neztero: {
    filename: 'netzero-lens.json',
    filter: assessment => assessment,
  },
  'un-sdgs': {
    filename: 'UN-SDGs-lens.json',
    filter: assessment => assessment,
  },
  education: {
    filename: 'education-lens.json',
    filter: assessment => assessment,
  },
  healthcare: {
    filename: 'healthcare-lens.json',
    filter: assessment => assessment,
  },
  'reseach-institute': {
    filename: 'research-institute-lens.json',
    filter: assessment => assessment,
  },
  'research-project': {
    filename: 'research-project-lens.json',
    filter: assessment => assessment,
  },
  'optimal-natural-resource-user': {
    filename: 'optimal-natural-resource-user-lens.json',
    filter: assessment => assessment,
  },
  'environmental-influencer': {
    filename: 'environmental-influencer-lens.json',
    filter: assessment => assessment,
  },
  'environmental-initiator': {
    filename: 'environmental-initiator-lens.json',
    filter: assessment => assessment,
  },
  'qa-rapid-organisation-diagnostic': {
    filename: 'rapid-organisation-diagnostic-lens.json',
    filter: assessment => assessment,
  },
  'qa-rapid-organisation-diagnostic-2025': {
    filename: 'rapid-organisation-diagnostic-2025-lens.json',
    filter: assessment => assessment,
  },
  'organisation-diagnostic': {
    filename: 'organisation-diagnostic.json',
    filter: assessment => assessment,
  },
}

const lensQuestionnaireFilter = key => assessment => {
  const res = assessment.assessmentTypes.find(x => x.key === key)
  res.translationNameSpace = assessment.translationNameSpace
  return res
}

const lensBmaFilter = key => assessment => {
  const result = deepClone(assessment.assessmentTypes.find(x => x.key === key))
  result.translationNameSpace = assessment.translationNameSpace

  let cidx = 1
  result.pillars.forEach(pillar => {
    pillar.criteria.forEach(criteria => {
      criteria.parts.forEach((part, pidx) => {
        const [table] = part.tables
        // Produce a name like "1.1: Xxxx"
        table.name = `${cidx}.${pidx + 1}: ${table.name}`
      })
      cidx++
    })
  })
  return result
}

const lensFilter = (key, lensVersionOf) => {
  switch (lensVersionOf) {
    case 'questionnaire-2020':
      return lensQuestionnaireFilter(key)
    case 'efqm-2020-advanced':
      return lensBmaFilter(key)
    default:
      return undefined
  }
}

const lensTypes = {
  // these are separate so that they aren't loaded by get assessment types,
  // they are however accessed via loadAssessmentType as needed
  'innovation-questionnaire': {
    filename: 'innovation-lens.json',
    filter: lensFilter('innovation-questionnaire', 'questionnaire-2020'),
  },
  'disruption-questionnaire': {
    filename: 'disruption-lens.json',
    filter: lensFilter('disruption-questionnaire', 'questionnaire-2020'),
  },
  'education-questionnaire': {
    filename: 'education-lens.json',
    filter: lensFilter('education-questionnaire', 'questionnaire-2020'),
  },
  'circular-economy-questionnaire': {
    filename: 'circular-economy-lens.json',
    filter: lensFilter('circular-economy-questionnaire', 'questionnaire-2020'),
  },
  'circular-economy-questionnaire-bma': {
    filename: 'circular-economy-lens.json',
    filter: lensFilter(
      'circular-economy-questionnaire-bma',
      'efqm-2020-advanced'
    ),
  },
  'netzero-questionnaire': {
    filename: 'netzero-lens.json',
    filter: lensFilter('netzero-questionnaire', 'questionnaire-2020'),
  },
  'un-sdgs-questionnaire': {
    filename: 'UN-SDGs-lens.json',
    filter: lensFilter('un-sdgs-questionnaire', 'questionnaire-2020'),
  },
  'un-sdgs-questionnaire-bma': {
    filename: 'UN-SDGs-lens.json',
    filter: lensFilter('un-sdgs-questionnaire-bma', 'efqm-2020-advanced'),
  },
  'innovation-bma': {
    filename: 'innovation-lens.json',
    filter: lensFilter('innovation-bma', 'efqm-2020-advanced'),
  },
  'environment-bma': {
    filename: 'environment-lens.json',
    filter: lensFilter('environment-bma', 'efqm-2020-advanced'),
  },
  'education-bma': {
    filename: 'education-lens.json',
    filter: lensFilter('education-bma', 'efqm-2020-advanced'),
  },
}

// Deep clone an object.
const deepClone = obj => JSON.parse(JSON.stringify(obj))

// Load an assessment or lens type.
const loadAssessmentType = (i18n, type) => {
  const assessmentType = assessmentTypes[type] || lensTypes[type]
  if (!assessmentType) {
    return {}
  }
  const { filter, filename } = assessmentType
  // Note that the following is a dynamic require and relies on Webpack's context
  // functionality in order for it to work in a bundled environment (e.g. in the
  // browser). See https://github.com/webpack/docs/wiki/context for details. Note
  // also that all language-specific assessment data files are stored under a common
  // parent folder (/data) to avoid bundling resources which aren't dynamically loaded.
  const assessment = require(`./data/en/${filename}`)

  if (assessment.translationNameSpace && i18n && i18n.getResourceBundle) {
    const translation = i18n.getResourceBundle(
      i18n.language,
      assessment.translationNameSpace
    )

    if (translation) {
      assessmentTranslationObjectRecursion(
        assessment,
        i18n.getResourceBundle(i18n.language, assessment.translationNameSpace)
      )
    }
  }

  return filter(assessment, type)
}

const assessmentTranslationObjectRecursion = (assessment, translation) => {
  if (assessment && translation) {
    for (key in assessment) {
      const valueType = typeof translation[key]

      if (valueType === 'object')
        assessmentTranslationObjectRecursion(assessment[key], translation[key])
      if (valueType === 'string') assessment[key] = translation[key]
    }
  }
}

/*
 * Replace static pre-built pageContext assessment template data with translated file of assessment template data
 *
 * @param {string} key The string identifier of the assessment-type
 * @param {object} i18n translation object
 * @param {object} pillar The pageContext pillar, if it exists
 * @param {object} criterion The pageContext criterion, if it exists
 * @param {object} part The pageContext criterion-part, if it exists
 *
 * @return {assessment, pillar, criterion, part} The assessment, and parts, retrieved for a given language
 */
const getAssessmentParts = (
  key,
  i18n,
  pillar = null,
  criterion = null,
  part = null
) => {
  // Load and filter assessment data.
  const assessment = loadAssessmentType(i18n, key)

  // Apply pillar/criterion/part filters.
  if (pillar) {
    pillar = assessment.pillars.find(p => p.key === pillar.key)
    if (pillar && criterion) {
      criterion = pillar.criteria.find(c => c.key === criterion.key)
      if (part && criterion) {
        const { key } = part.tables[0]
        part = criterion.parts.find(p => p.tables[0].key === key)
      }
    }
  }

  return { assessment, pillar, criterion, part }
}

/*
 * Replace static pre-built pageContext key-information data with translated file of key-information data
 *
 * @param {string} key The string identifier of the key-information part
 * @param {string} lang The string identifier of the language (2 character lower-case ISO code)
 *
 * @return {object} The markdown text of the desired language
 */
const getKeyInfo = key => (keyInfo.includes(key) ? key : '')

/**
 * Return localized assessment type configurations.
 *
 * @param {string} lang The string identifier of the language (2 character lower-case ISO code)
 * @param {object} i18n translation object
 *
 * @return {array} Array of objects representing each available assessment type
 */
const getAssessmentTypes = i18n => {
  const result = []
  for (const type in assessmentTypes) {
    result.push(loadAssessmentType(i18n, type))
  }
  return result
}

/**
 * Return localized names for the different assessment types.
 *
 * @param {string} lang The string identifier of the language (2 character lower-case ISO code)
 * @param {object} i18n translation object
 *
 * @return {object} A map of assessment type keys to localized assessment names.
 */
const getAssessmentTypeNames = i18n => {
  // Note: this isn't currently used anywhere
  const result = {}
  for (const type in assessmentTypes) {
    const assessment = loadAssessmentType(i18n, type)
    result[type] = assessment.name
  }
  return result
}

module.exports = {
  getAssessmentParts,
  getKeyInfo,
  getAssessmentTypes,
  getAssessmentTypeNames,
}
