export const migratedAssessments = [
  'qa-research-institute',
  'qa-research-project',
  'qa-optimal-natural-resource-user',
  'qa-environmental-initiator',
  'qa-environmental-influencer',
  'qa-rapid-organisation-diagnostic',
  'qa-rapid-organisation-diagnostic-2025',
  'rapid-organisation-diagnostic',
  'rapid-organisation-diagnostic-2025',
  'organisation-diagnostic',
  'organisation-diagnostic-2025',
  'advanced-organisation-diagnostic-2025',
]
